import http from '@/units/request'
/** *
 * 获取人员通讯录
 * ***/
export const getUserAddress = (data) => {
  return http({
    url: '/is-user/addressBook/v2/list',
    method: 'get',
    params: data
  })
}
/** *
 * 获取人员详情
 * ***/
export const getUserInfo = (userId) => {
  return http({
    url: `/is-user/addressBook/v2/info/${userId}`,
    method: 'get'
  })
}
