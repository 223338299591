<template>
  <div
    class="commonAddressBook van-popup--safe-area-inset-bottom"
    v-watermark
  >
    <van-nav-bar
      title="选择替换人员"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      safe-area-inset-top
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="list">
      <van-radio-group v-model="result">
        <van-index-bar :index-list="indexList">
          <div
            v-for="index in indexList"
            :key="index"
          >
            <van-index-anchor :index="index">
              {{ index }}
            </van-index-anchor>
            <van-cell-group>
              <van-cell
                v-for="(item, key) in userAddress[index]"
                clickable
                :key="key"
                :title="item.name"
                @click="clickHandler(JSON.stringify(item))"
              >
                <template #icon>
                  <van-radio :name="JSON.stringify(item)" />
                </template>
              </van-cell>
            </van-cell-group>
          </div>
        </van-index-bar>
      </van-radio-group>
    </div>
    <div class="commonBox">
      <div class="selectList">
        <span v-if="result">{{ JSON.parse(result).name }}</span>
      </div>
      <van-button
        type="info"
        @click="onClickTest"
        size="small"
      >
        确定
      </van-button>
    </div>
  </div>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import { getUserAddress } from '@/api/addressBook'

export default {
  name: 'commonAddressBook',
  created() {
    this.result = this.selectList
    this.getUserAddress()
  },
  data() {
    return {

      indexList: [],
      userAddress: {},
      result: ''
    }
  },
  computed: {
    ...mapState('sendMessagePerson', ['selectList'])
  },
  methods: {
    ...mapMutations('sendMessagePerson', ['setSelectList']),
    onClickTest() {
      this.setSelectList(this.result)
      this.$router.back()
    },
    // 选择人员
    clickHandler(val) {
      this.result = val
    },
    // 获取人员通讯录
    async getUserAddress() {
      const { data } = await getUserAddress({
        departmentCode: [this.$route.params.type].toString()
      })
      this.indexList = Object.keys(data.data.initials)
      this.userAddress = data.data.initials
    }
  }
}
</script>

<style lang="scss" scoped>
.commonAddressBook {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  ::v-deep {
    .list {
      height: calc(100% - 50px - 46px);
      overflow: auto;

      .van-cell__title {
        span {
          margin-left: 15px;
        }
      }
    }

    .commonBox {
      height: 50px;
      width: 100%;
      background-color: rgb(238, 238, 238);
      align-items: center;
      display: flex;
      box-sizing: initial;

      .van-button {
        min-width: 59px;
        margin-right: 10px;
      }

      .selectList {
        flex: 1;
        height: 100%;
        font-size: 14px;
        line-height: 50px;
        padding-left: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
